<template>
  <div
    :data-mds-version="version"
    :class="$style['mds-date-picker']"
    :aria-expanded="showDatePicker ? 'true' : 'false'"
    :aria-controls="containerId"
    v-click-outside:hideDatePicker="{ inputId, calendarId }"
    aria-haspopup="true"
  >
    <mds-label
      :on-dark="onDark"
      :size="size"
      :optional="optional"
      :optional-text="optionalText"
      :required="required"
      :required-text="requiredText"
      :text="label"
      :hidden-label="hiddenLabel"
      :for="inputId"
    >
      <slot name="mds-microcopy-above">
        <mds-microcopy
          v-if="microcopyAbove"
          :size="size"
          :on-dark="onDark"
          v-html="microcopyAbove"
        />
      </slot>
      <div :class="$style['mds-date-picker__wrapper']">
        <div :class="$style['mds-date-picker__icon-wrapper']">
          <input
            ref="input"
            :class="[
              $style['mds-date-picker__input'],
              inputClassObject,
              inputSizeClass
            ]"
            :id="inputId"
            :value="formattedDate"
            :required="required"
            :aria-invalid="error"
            v-bind="$attrs"
            :maxlength="typing ? $attrs.maxlength : 0"
            @input="handleUserInput"
            @focus="openDatePicker"
            @click="openDatePicker"
            @keydown.delete="handleUserKeyDelete"
            @keydown.enter.prevent="openDatePicker"
            aria-autocomplete="none"
          />
          <mds-button
            :class="$style['mds-date-picker__clear-button']"
            :size="iconSize"
            :on-dark="onDark"
            :text="clearIconAriaLabel"
            @click="clearSelection()"
            v-if="showClearButton"
            type="button"
            variation="icon-only"
            icon="remove"
          ></mds-button>
          <mds-icon
            :class="[
              $style['mds-date-picker__icon'],
              iconClassObject,
              iconSizeClass
            ]"
            :size="iconSize"
            v-if="!showClearButton"
            name="calendar"
          ></mds-icon>
        </div>
        <transition
          :enter-active-class="
            $style['mds-date-picker__container-enter-active']
          "
          :leave-active-class="
            $style['mds-date-picker__container-leave-active']
          "
          :enter-class="$style['mds-date-picker__container-enter']"
          :leave-to-class="$style['mds-date-picker__container-leave-to']"
        >
          <div
            ref="popover"
            v-if="showDatePicker"
            :class="$style['mds-date-picker__container']"
            :id="containerId"
            :aria-hidden="showDatePicker ? 'false' : 'true'"
            :aria-roledescription="labelsMerged.roleDescription"
            v-keyboard-navigation="navigate"
            v-auto-direction="positionResults"
            role="application"
            aria-modal="true"
          >
            <div :class="$style['mds-date-picker__header']">
              <div :class="$style['mds-date-picker__header-title']">
                <mds-select
                  :class="$style['mds-date-picker__select']"
                  :label="labelsMerged.select.month"
                  v-model.number="month"
                  :options="monthOptions"
                  hidden-label
                >
                </mds-select>
                <mds-select
                  :class="$style['mds-date-picker__select']"
                  :label="labelsMerged.select.year"
                  v-model.number="year"
                  :options="years"
                  hidden-label
                ></mds-select>
              </div>
              <mds-button-container right-aligned>
                <mds-button
                  :disabled="isFirstMonth"
                  :text="getButtonLabel(PREVIOUS)"
                  :aria-disabled="isFirstMonth"
                  @click="handleButtonClick(PREVIOUS)"
                  variation="icon-only"
                  icon="angle-left"
                  type="button"
                ></mds-button>
                <mds-button
                  :disabled="isLastMonth"
                  :text="getButtonLabel(NEXT)"
                  :aria-disabled="isLastMonth"
                  @click="handleButtonClick(NEXT)"
                  variation="icon-only"
                  icon="angle-right"
                  type="button"
                ></mds-button>
              </mds-button-container>
            </div>
            <div
              :class="$style['mds-date-picker__days-of-the-week']"
              aria-hidden="true"
            >
              <div
                :class="$style['mds-date-picker__day-of-the-week']"
                v-for="days in daysOfTheWeek"
                :key="days"
              >
                {{ days }}
              </div>
            </div>
            <div
              :aria-label="formattedFullDate"
              :aria-activedescendant="focusedDateId"
              :class="$style['mds-date-picker__calendar']"
              :id="calendarId"
              @keydown.self="handleKeyboardNavigation"
              @focus="handleFocus"
              @mouseover="handleMouseOver"
              @mouseout="handleMouseOut"
              tabindex="0"
              aria-live="polite"
            >
              <div
                :class="$style['mds-date-picker__week']"
                v-for="(week, index) in weeks"
                :key="index"
              >
                <div
                  v-for="d in week"
                  :key="d.id"
                  :id="d.id"
                  :class="[$style['mds-date-picker__day'], getClassObject(d)]"
                  :aria-label="d.formattedDate"
                  :aria-disabled="d.isDisabled"
                  :aria-pressed="`${d.isSelected}`"
                  :aria-current="d.isFocused"
                  @click.prevent="handleClick(d)"
                  role="button"
                >
                  <span>{{ d.day }}</span>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div
        v-if="error"
        :class="$style['mds-date-picker__field-error-wrapper']"
        aria-live="assertive"
        role="alert"
      >
        <mds-field-error
          v-for="(text, index) in errorText"
          :key="index"
          v-show="error"
          :size="size"
          v-html="text"
          :on-dark="onDark"
        />
      </div>
      <slot name="mds-microcopy-below">
        <mds-microcopy
          v-if="microcopyBelow"
          :size="size"
          v-html="microcopyBelow"
          :on-dark="onDark"
        />
      </slot>
    </mds-label>
  </div>
</template>

<script>
import MdsIcon from '@mds/icon';
import MdsLabel from '@mds/label';
import MdsFieldError from '@mds/field-error';
import MdsMicrocopy from '@mds/microcopy';
import MdsSelect from '@mds/select';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MDSKeyboardNavigation } from '@mds/utils-js';
import { DATE_FORMAT, LABELS, PREVIOUS, NEXT } from './constants.js';

import * as Utils from './date-picker_utils.js';
import DatePickerDirectives from './date-picker_directives.js';

export default {
  name: 'MdsDatePickerBase',
  components: {
    MdsLabel,
    MdsFieldError,
    MdsMicrocopy,
    MdsIcon,
    MdsSelect,
    MdsButton,
    MdsButtonContainer
  },
  directives: {
    clickOutside: DatePickerDirectives.clickOutside,
    autoDirection: MDSKeyboardNavigation.autoDirection,
    keyboardNavigation: MDSKeyboardNavigation.keyboardNavigation
  },
  inheritAttrs: false,
  props: {
    clearIconAriaLabel: {
      type: String,
      default: 'Clear date picker field',
      docs: {
        validation: '—',
        description: 'Sets the `aria-label` on the icon-only clear button.'
      }
    },
    dateFormat: {
      type: Object,
      validator({
        month = undefined,
        weekday = undefined,
        year = undefined,
        day = undefined
      }) {
        return (
          ['numeric', '2-digit', 'long', 'short', 'narrow', undefined].indexOf(
            month
          ) !== -1 &&
          ['long', 'short', 'narrow', 'none', undefined].indexOf(weekday) !==
            -1 &&
          ['numeric', '2-digit', undefined].indexOf(year) !== -1 &&
          ['numeric', '2-digit', undefined].indexOf(day) !== -1
        );
      },
      default: () => DATE_FORMAT,
      docs: {
        validation: '—',
        defaultOverride:
          'Refer to the [content schema](#content-schema-for-dateformat) defined below.',
        description:
          'Sets the date format used in the date picker input field, using the [content schema](#content-schema-for-dateformat) defined below. Individual config can be overridden on an as-needed basis and will be merged with the default date format.'
      }
    },
    disableDates: {
      type: [Array, Function],
      default: () => [],
      docs: {
        validation: '—',
        description:
          'Sets the dates that will be disabled within the calendar picker.'
      }
    },
    displayTop: {
      type: Boolean,
      default: false,
      docs: {
        validation: '—',
        description:
          'If `true`, positions the popover above the date picker input field.'
      }
    },
    error: {
      type: Boolean,
      default: false,
      docs: {
        validation: '—',
        description:
          'If `true`, triggers the error state and shows the `errorText` for the input.'
      }
    },
    errorText: {
      type: Array,
      default: null,
      docs: {
        validation: '—',
        description:
          'Sets the error text that appears when `error=true` for the input.',
        support: ['v-html']
      }
    },
    hiddenLabel: {
      type: Boolean,
      default: false,
      docs: {
        validation: '—',
        description: 'If `true`, visibly hides the input’s label.'
      }
    },
    highlightToday: {
      type: Boolean,
      default: false,
      docs: {
        validation: '—',
        description: 'Sets the styling for today.'
      }
    },
    initialDate: {
      type: Object,
      validator(value) {
        return (
          Object.keys(value).length === 0 ||
          ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].indexOf(value.month) !==
            -1 &&
            value.year.toString().length === 4)
        );
      },
      default: () => ({}),
      docs: {
        validation:
          '`month`: Numeric, e.g. `10`. `year`: Numeric, e.g. `2020`.',
        description: 'Sets the initial month and year shown in the date picker.'
      }
    },
    label: {
      type: String,
      required: true,
      docs: {
        validation: '**Required**',
        description: 'Sets the label for the input.'
      }
    },
    labels: {
      type: Object,
      validator({
        month = undefined,
        weekday = undefined,
        year = undefined,
        day = undefined
      }) {
        return (
          ['numeric', '2-digit', 'long', 'short', 'narrow', undefined].indexOf(
            month
          ) !== -1 &&
          ['short', 'narrow', undefined].indexOf(weekday) !== -1 &&
          ['numeric', '2-digit', undefined].indexOf(year) !== -1 &&
          ['numeric', '2-digit', undefined].indexOf(day) !== -1
        );
      },
      default: () => LABELS,
      docs: {
        validation: '—',
        defaultOverride:
          'Refer to the [content schema](#content-schema-for-labels) defined below.',
        description:
          'Sets the labels used in the calendar picker, using the [content schema](#content-schema-for-labels) defined below. Individual config can be overridden on an as-needed basis and will be merged with the default labels.'
      }
    },
    microcopyAbove: {
      type: String,
      default: null,
      docs: {
        validation: '—',
        description: 'Sets the microcopy above the input.',
        support: ['v-html']
      }
    },
    microcopyBelow: {
      type: String,
      default: null,
      docs: {
        validation: '—',
        description: 'Sets the microcopy below the input.',
        support: ['v-html']
      }
    },
    minMaxDates: {
      type: Object,
      required: true,
      validator(value) {
        const validMin = !!value.min && typeof value.min.getTime === 'function';
        const validMax = !!value.max && typeof value.max.getTime === 'function';
        return 'min' in value && 'max' in value && validMin && validMax;
      },
      docs: {
        validation: '—',
        description:
          'Sets the minumum and maximum dates available in the calendar picker and disables the dates outside of those bounds.'
      }
    },
    onDark: {
      type: Boolean,
      default: false,
      docs: {
        validation: '—',
        description: 'If `true`, changes component to on-dark styling.'
      }
    },
    optional: {
      type: Boolean,
      default: false,
      docs: {
        validation: '—',
        description:
          'If `true`, displays the `optionalText` on the input label.'
      }
    },
    optionalText: {
      type: String,
      default: '(Optional)',
      docs: {
        validation: '—',
        description: 'Sets the label text to describe an input as optional.'
      }
    },
    required: {
      type: Boolean,
      default: false,
      docs: {
        validation: '—',
        description:
          'If `true`, displays the required indicator (*) on the input label.'
      }
    },
    requiredText: {
      type: String,
      default: 'This field is required',
      docs: {
        validation: '—',
        description:
          'Sets the `aria-label` text to describe an input as required.'
      }
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large', 'touch'].indexOf(value) !== -1;
      },
      docs: {
        validation: 'One of: `small`, `medium`, `large`, `touch`.',
        description: 'Sets the size of the input.'
      }
    },
    typing: {
      type: Boolean,
      default: false,
      docs: {
        validation: '—',
        description:
          'If `true`, allows typing in the input. If `false`, only allows the backspace key to clear the value from the input.'
      }
    },
    value: {
      type: [String, Date],
      default: '',
      validator(value) {
        return typeof value === 'string' || Utils.checkValidDate(value);
      },
      docs: {
        validation: '—',
        description: 'Sets the selected date for the date picker.',
        support: ['v-model']
      }
    }
  },
  data() {
    return {
      year: null,
      month: null,
      day: null,
      weeks: [],
      selectedDate: '',
      focusedDate: null,
      focusedDateId: '',
      isFirstMonth: false,
      isLastMonth: false,
      monthOptions: [],
      showDatePicker: false,
      isCalendarActive: true,
      isMouseOverCalendar: false,
      PREVIOUS,
      NEXT,
      version: '@mds/date-picker-3.2.8'
    };
  },
  computed: {
    formattedDate() {
      const isValidDate = Utils.checkValidDate(this.selectedDate);
      return isValidDate
        ? Utils.formatDate(this.selectedDate, this.dateFormatMerged)
        : this.value;
    },
    inputSizeClass() {
      return this.$style[`mds-date-picker__input--${this.size}`];
    },
    inputClassObject() {
      const obj = {};
      obj[this.$style['mds-date-picker__input--error']] = this.error;
      obj[this.$style['mds-date-picker__input--on-dark']] = this.onDark;

      return obj;
    },
    iconSize() {
      return this.size === 'small' || this.size === 'medium'
        ? 'small'
        : 'medium';
    },
    iconSizeClass() {
      return this.$style[`mds-date-picker__input-icon--${this.size}`];
    },
    iconClassObject() {
      const obj = {};
      obj[this.$style['mds-date-picker__input-icon--on-dark']] = this.onDark;

      return obj;
    },
    inputId() {
      return `mds-date-picker-input-${this.getRandomId()}`;
    },
    containerId() {
      return `mds-date-picker-container-${this.getRandomId()}`;
    },
    calendarId() {
      return `mds-date-picker-calendar-${this.getRandomId()}`;
    },
    minYear() {
      return this.minMaxDates.min.getFullYear();
    },
    maxYear() {
      return this.minMaxDates.max.getFullYear();
    },
    labelsMerged() {
      return Object.assign({}, LABELS, this.labels);
    },
    dateFormatMerged() {
      return Object.assign({}, DATE_FORMAT, this.dateFormat);
    },
    months() {
      return Utils.getMonths(this.labelsMerged);
    },
    daysOfTheWeek() {
      return Utils.getDaysOfTheWeek(this.labelsMerged);
    },
    years() {
      return Utils.getYears(
        this.minYear,
        this.maxYear,
        this.labelsMerged,
        this.enabledDates.years
      );
    },
    enabledDates() {
      return Utils.getEnabledDates(this.minMaxDates, this.disableDates);
    },
    computedWeeks() {
      return {
        selectedDate: this.selectedDate,
        disableDates: this.disableDates,
        focusedDate: this.focusedDate
      };
    },
    fullDate() {
      return {
        year: this.year,
        month: this.month
      };
    },
    formattedFullDate() {
      const monthIndex = this.month - 1;
      const date = Utils.createDate(this.year, monthIndex);
      const { locale, month, year } = this.labelsMerged;
      return Utils.formatDate(date, { locale, month, year });
    },
    showClearButton() {
      return this.selectedDate && this.showDatePicker;
    }
  },
  watch: {
    value: 'setInitialSelection',
    computedWeeks() {
      this.setMonthOptions();
      this.$nextTick(() => {
        this.updateMonth();
        this.updateWeeks();
      });
    },
    fullDate() {
      this.setMonthOptions();
      this.$nextTick(() => {
        this.updateMonth(this.year);
        const { isFirstMonth, isLastMonth } = Utils.checkFirstAndLastMonth(
          this.monthOptions,
          this.minYear,
          this.maxYear,
          this.year,
          this.month
        );
        this.isFirstMonth = isFirstMonth;
        this.isLastMonth = isLastMonth;
        const newDate = this.updateDate(this.year, this.month);
        this.updateFocus(newDate);
        this.updateWeeks();
      });
    }
  },
  created() {
    const today = Utils.createDate();
    this.year =
      (this.initialDate && this.initialDate.year) || today.getFullYear();
    this.month =
      (this.initialDate && this.initialDate.month) || today.getMonth() + 1;
    this.setMonthOptions();
    this.setInitialSelection();
    this.updateMonth();
    this.day = Utils.getFirstEnabledDay(
      this.year,
      this.month,
      this.minMaxDates,
      this.disableDates,
      today.getDate()
    );
    const monthIndex = this.month - 1;
    const date = Utils.createDate(this.year, monthIndex, this.day);
    this.updateFocus(date);
    const { weeks, focusedDateId } = Utils.getWeeks(
      this.year,
      this.month,
      this.selectedDate,
      this.disableDates,
      this.minMaxDates,
      this.focusedDate,
      this.labelsMerged
    );
    this.weeks = weeks;
    this.focusedDateId = focusedDateId;
  },
  methods: {
    handleUserInput(e) {
      if (this.typing) {
        this.$emit('input', e.target.value);
        /**
         * DEPRECATED: remove mds-date-picker-input-changed in future major version
         * in favor of "input"
         */
        this.$emit('mds-date-picker-input-changed', e.target.value);
        this.openDatePicker();
      } else {
        e.preventDefault();
      }
    },
    /**
     * input event cannot prevent the delete key behavior so use keydown
     */
    handleUserKeyDelete(e) {
      if (!this.typing) {
        e.preventDefault();
        this.clearSelection();
      }
    },
    updateSelection(date) {
      this.selectedDate = date;
      this.$emit('input', date);
      this.hideDatePicker();
    },
    getRandomId() {
      const randomId = Math.random() * Math.floor(1000000);
      return Math.round(randomId);
    },
    setMonthOptions() {
      let monthOptions = this.months.map((month, index) => ({
        text: month,
        value: index + 1
      }));
      const year = this.year;
      if (year === this.minYear) {
        monthOptions = monthOptions.slice(
          this.minMaxDates.min.getMonth(),
          monthOptions.length
        );
      } else if (year === this.maxYear) {
        monthOptions = monthOptions.slice(
          0,
          this.minMaxDates.max.getMonth() + 1
        );
      }
      monthOptions = monthOptions.filter(
        (m) => this.enabledDates.months[year].indexOf(m.value) !== -1
      );
      this.monthOptions = monthOptions;
    },
    updateMonth(newYear = null) {
      const year = newYear || this.year;
      const monthIndex = this.month - 1;
      const date = Utils.createDate(year, monthIndex, this.day);
      const isInRange = Utils.checkInRange(this.minMaxDates, date);
      if (!isInRange) {
        this.month = Utils.getValidMonth(
          this.minMaxDates,
          date,
          this.monthOptions
        );
      }
    },
    updateWeeks() {
      const { weeks, focusedDateId } = Utils.getWeeks(
        this.year,
        this.month,
        this.selectedDate,
        this.disableDates,
        this.minMaxDates,
        this.focusedDate,
        this.labelsMerged
      );
      this.weeks = weeks;
      this.focusedDateId = focusedDateId;
    },
    openDatePicker() {
      this.showDatePicker = true;
    },
    hideDatePicker() {
      this.showDatePicker = false;
    },
    positionResults(el) {
      const selectionOffset = this.$refs.input.getBoundingClientRect().height;
      if (this.displayTop) {
        el.style.cssText = `bottom: ${selectionOffset ? selectionOffset : 0}px`;
      } else {
        el.style.cssText = `top: ${selectionOffset}px`;
      }
    },
    setInitialSelection() {
      this.selectedDate = this.value;
      const isValidDate = Utils.checkValidDate(this.value);
      if (isValidDate) {
        const year = this.value.getFullYear();
        const month = this.value.getMonth() + 1;
        const day = this.value.getDate();
        const newDate = this.updateDate(year, month, day);
        this.updateFocus(newDate);
      }
    },
    updateDate(year, month, day = null) {
      let newYear = year || this.year;
      let newMonth = Number.isInteger(month) ? month : this.month;
      ({ year: newYear, month: newMonth } = Utils.normalizeMonthIndex(
        newYear,
        newMonth
      ));
      const newDay = Utils.getFirstEnabledDay(
        newYear,
        newMonth,
        this.minMaxDates,
        this.disableDates,
        day || this.day
      );
      const monthIndex = newMonth - 1;
      let newDate = Utils.createDate(newYear, monthIndex, newDay);
      const isInRange = Utils.checkInRange(this.minMaxDates, newDate);
      // Skip range check if we are updating month
      if (isInRange || day === null) {
        this.year = newDate.getFullYear();
        this.month = newDate.getMonth() + 1;
        this.day = newDate.getDate();
      }
      return newDate;
    },
    handleButtonClick(mode) {
      let { year, month } = Utils.moveMonth(
        this.year,
        this.month,
        this.minMaxDates,
        this.enabledDates,
        mode
      );
      const newDate = this.updateDate(year, month);
      this.updateFocus(newDate);
    },
    getButtonLabel(mode) {
      if (
        (mode === PREVIOUS && this.isFirstMonth) ||
        (mode === NEXT && this.isLastMonth)
      ) {
        return this.labelsMerged.button[mode];
      }
      let { year, month } = Utils.moveMonth(
        this.year,
        this.month,
        this.minMaxDates,
        this.enabledDates,
        mode
      );
      const date = Utils.createDate(year, month, 0);
      const monthLabel = Utils.formatDate(date, {
        locale: this.labelsMerged.locale,
        month: this.labelsMerged.month
      });
      return `${this.labelsMerged.button[mode]}, ${monthLabel}`;
    },
    setCalendarActive(state) {
      this.isCalendarActive = state;
    },
    setMouseOverCalendar(state) {
      this.isMouseOverCalendar = state;
    },
    handleFocus() {
      if (!this.isMouseOverCalendar) {
        this.setCalendarActive(true);
      }
    },
    handleMouseOver() {
      this.setMouseOverCalendar(true);
      this.setCalendarActive(false);
    },
    handleMouseOut() {
      this.setMouseOverCalendar(false);
    },
    navigate(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.hideDatePicker();
      }
    },
    handleKeyboardNavigation(event) {
      let year, month, day;
      const dateArr = [this.year, this.month, this.day];
      let isSelected = false;
      const monthIndex = this.month - 1;
      const date = Utils.createDate(this.year, monthIndex, this.day);

      switch (event.key) {
        case 'Left':
        case 'Right':
        case 'Up':
        case 'Down':
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'ArrowUp':
        case 'ArrowDown':
          event.preventDefault();
          this.setCalendarActive(true);
          ({ year, month, day } = Utils.moveDate(
            ...dateArr,
            this.minMaxDates,
            this.disableDates,
            event.key
          ));
          break;
        case 'Enter':
          event.preventDefault();
          this.setCalendarActive(true);
          isSelected = Utils.checkSameDates(this.selectedDate, date);
          if (isSelected) {
            this.clearSelection();
          } else {
            this.updateSelection(date);
          }
          break;
        default:
          return;
      }

      if (!isSelected) {
        const newDate = this.updateDate(year, month, day);
        this.updateFocus(newDate);
      }
    },
    handleClick(d) {
      const { year, month, day, isSelected, isDisabled } = d;
      if (isSelected) {
        this.clearSelection();
        return;
      } else if (!isDisabled) {
        const newDate = this.updateDate(year, month, day);
        this.updateSelection(newDate);
        this.updateFocus(newDate);
      }
    },
    updateFocus(date) {
      this.focusedDate = date;
    },
    clearSelection() {
      this.selectedDate = '';
      this.$emit('input', '');
    },
    getClassObject(d) {
      const obj = {};
      obj[this.$style['mds-date-picker__day']] = true;
      obj[this.$style['mds-date-picker__day--today']] =
        this.highlightToday && d.isToday;
      obj[this.$style['mds-date-picker__day--not-in-month']] =
        !d.isCurrentMonth;
      obj[this.$style['mds-date-picker__day--selected']] =
        !d.isDisabled && d.isSelected;
      obj[this.$style['mds-date-picker__day--disabled']] = d.isDisabled;
      obj[this.$style['mds-date-picker__day--focused']] =
        !d.isSelected && d.isFocused && this.isCalendarActive;
      return obj;
    }
  }
};
</script>

<style lang="scss" module>
@import './date-picker.scss';
</style>
