// Constant numbers
const ONE_MONTH = 1;
const ONE_DAY = 1;
const FIRST_DAY = 1; // The first day of a month is 1st
const DAYS_IN_ONE_WEEK = 7;
const MONTHS_IN_ONE_YEAR = 12;
const MAX_MONTH_INDEX = 11;
const MAX_DAYS_IN_ONE_MONTH = 31;

// Navigation buttons
const PREVIOUS = 'previous';
const NEXT = 'next';

// Locale
const LOCALE = 'en-US';
const WEEKDAY = 'short';
const MONTH = 'numeric';
const YEAR = 'numeric';
const DAY = 'numeric';
const SELECT = {
  month: 'Month',
  year: 'Year'
};
const NONE = 'none';
const BUTTON = {
  // Ensure these keys align with navigation buttons
  previous: 'Move to Previous Month',
  next: 'Move to Next Month'
};
const ROLE_DESCRIPTION = 'datepicker';
const LABELS = {
  locale: LOCALE,
  month: MONTH,
  weekday: WEEKDAY,
  year: YEAR,
  day: DAY,
  select: SELECT,
  button: BUTTON,
  roleDescription: ROLE_DESCRIPTION
};
const DATE_FORMAT = {
  locale: LOCALE,
  month: MONTH,
  weekday: NONE,
  year: YEAR,
  day: DAY
};

export {
  // Constant numbers
  ONE_MONTH,
  ONE_DAY,
  FIRST_DAY,
  DAYS_IN_ONE_WEEK,
  MONTHS_IN_ONE_YEAR,
  MAX_MONTH_INDEX,
  MAX_DAYS_IN_ONE_MONTH,
  // Navigation buttons
  PREVIOUS,
  NEXT,
  // Locale
  LOCALE,
  WEEKDAY,
  MONTH,
  YEAR,
  DAY,
  LABELS,
  DATE_FORMAT,
  NONE
};
