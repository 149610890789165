export default {
  clickOutside: {
    bind(el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          vnode.context[binding.arg](event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
      el.tabOutsideEvent = function (event) {
        if (event.key == 'Tab') {
          if (!event.shiftKey && event.target.id === binding.value.calendarId) {
            vnode.context[binding.arg](event);
          } else if (
            event.shiftKey &&
            event.target.id === binding.value.inputId
          ) {
            vnode.context[binding.arg](event);
          }
        }
      };
      document.body.addEventListener('keydown', el.tabOutsideEvent);
    },
    unbind(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    }
  }
};
