import {
  ONE_MONTH,
  ONE_DAY,
  FIRST_DAY,
  DAYS_IN_ONE_WEEK,
  MONTHS_IN_ONE_YEAR,
  MAX_MONTH_INDEX,
  MAX_DAYS_IN_ONE_MONTH,
  PREVIOUS,
  NEXT,
  NONE
} from './constants.js';

const createDate = (...args) => {
  return new Date(...args);
};

const getDaysInMonth = (year, month) => {
  return createDate(year, month, 0).getDate();
};

const getPreviousMonth = (year, month) => {
  const date = createDate(year, month % MAX_MONTH_INDEX);
  date.setMonth(month - ONE_MONTH);

  return {
    year: date.getFullYear(),
    month: date.getMonth()
  };
};

const getNextMonth = (year, month) => {
  const date = createDate(year, month % MAX_MONTH_INDEX);
  date.setMonth(month + ONE_MONTH);

  return {
    year: date.getFullYear(),
    month: date.getMonth()
  };
};

const moveMonth = (yearInput, monthInput, minMaxDates, enabledDates, mode) => {
  let year = yearInput;
  let month = monthInput;
  let isDisabled = false;
  let isInRange = false;
  let date;
  let monthIndex;

  do {
    monthIndex = month - 1;
    date = createDate(year, monthIndex, 0);
    isInRange = checkInRange(minMaxDates, date);
    if (mode === PREVIOUS) {
      ({ year, month } = getPreviousMonth(year, month));
    } else if (mode === NEXT) {
      ({ year, month } = getNextMonth(year, month));
    }
    ({ year, month } = normalizeMonthIndex(year, month));
    isDisabled =
      enabledDates.months[year] &&
      enabledDates.months[year].indexOf(month) === -1;
  } while (isDisabled && isInRange);

  return { year, month };
};

/**
 * Default month in JS ranges from 0 to 11. This method normalizes it to 1 to 12.
 * @example input (2021, 1) output { year: 2021, month: 1}
 * @example input (2021, 0) output { year: 2020, month: 12}
 */
const normalizeMonthIndex = (yearInput, monthInput) => {
  const date = createDate(yearInput, monthInput, 0);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return { year, month };
};

const moveDate = (year, month, day, minMaxDates, disableDates, key) => {
  const monthIndex = month - 1;
  const oldDate = createDate(year, monthIndex, day);
  let newDay = day;
  let previous = false;
  let next = false;

  switch (key) {
    case 'ArrowLeft':
    case 'Left':
      newDay -= ONE_DAY;
      previous = true;
      break;
    case 'ArrowRight':
    case 'Right':
      newDay += ONE_DAY;
      next = true;
      break;
    case 'ArrowUp':
    case 'Up':
      newDay -= DAYS_IN_ONE_WEEK;
      previous = true;
      break;
    case 'ArrowDown':
    case 'Down':
      newDay += DAYS_IN_ONE_WEEK;
      next = true;
      break;
  }

  let isDisabled = false;
  let isInRange = false;
  let date;
  do {
    date = createDate(year, monthIndex, newDay);
    isDisabled = checkDisabled(disableDates, date);
    isInRange = checkInRange(minMaxDates, date);
    if (previous) {
      newDay -= ONE_DAY;
    } else if (next) {
      newDay += ONE_DAY;
    }
  } while (isDisabled && isInRange);

  if (isDisabled || !isInRange) {
    // Do not update the date if it is invalid
    date = oldDate;
  }

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  };
};

const checkValidDate = (date) => {
  return (
    !!date &&
    typeof date.getTime === 'function' &&
    date.toString() !== 'Invalid Date'
  );
};

const checkValidDates = (dates) => {
  return (
    dates &&
    Array.isArray(dates) &&
    dates.length > 0 &&
    dates.every(checkValidDate)
  );
};

const checkSameDates = (date1, date2) => {
  if (checkValidDate(date1) && checkValidDate(date2)) {
    return date1.getTime() === date2.getTime();
  }
  return false;
};

const checkToday = (date) => {
  const today = createDate();
  today.setHours(0, 0, 0, 0);
  return checkSameDates(today, date);
};

const getFirstDayIndexInMonth = (year, month) => {
  const monthIndex = month - 1;
  return createDate(year, monthIndex, FIRST_DAY).getDay();
};

const checkInRange = (minMaxDates, date) => {
  const { min, max } = minMaxDates;
  if (checkValidDate(min) && checkValidDate(max) && checkValidDate(date)) {
    const beforeMin = date.getTime() < min.getTime();
    const afterMax = max.getTime() < date.getTime();
    const isInRange = !beforeMin && !afterMax;
    return isInRange;
  }
  return false;
};

const getValidMonth = (minMaxDates, date, monthOptions) => {
  const { min, max } = minMaxDates;
  if (checkValidDate(min) && checkValidDate(max) && checkValidDate(date)) {
    const currentMonth = date.getMonth() + 1;
    const beforeMin = date.getTime() < min.getTime();
    const afterMax = max.getTime() < date.getTime();
    const isInRange = !beforeMin && !afterMax;

    return isInRange
      ? currentMonth
      : beforeMin
      ? monthOptions[0].value
      : monthOptions[monthOptions.length - 1].value;
  }
  return null;
};

const checkDisabled = (disableDates, date) => {
  if (typeof disableDates === 'function') {
    return disableDates(date);
  }
  if (checkValidDates(disableDates) && checkValidDate(date)) {
    return disableDates.some((d) => d.getTime() === date.getTime());
  }
  return false;
};

const getDayInfo = (
  year,
  month,
  day,
  selectedDate,
  disableDates,
  minMaxDates,
  focusedDate,
  labelsMerged,
  isCurrentMonth
) => {
  const date = createDate(year, month - 1, day);
  const isInRange = checkInRange(minMaxDates, date);
  const isFocused = checkSameDates(focusedDate, date);

  return {
    id: `${year}-${month}-${day}`,
    formattedDate: formatDate(date, labelsMerged),
    date,
    year,
    month,
    day,
    isCurrentMonth,
    isToday: checkToday(date),
    isSelected: checkSameDates(selectedDate, date),
    isFocused,
    isDisabled: checkDisabled(disableDates, date) || !isInRange
  };
};

const getWeeks = (
  currentYear,
  currentMonth,
  selectedDate,
  disableDates,
  minMaxDates,
  focusedDate,
  labelsMerged
) => {
  let focusedDateId = null;
  let isPreviousMonth = true;
  let isCurrentMonth = false;
  let weeks = [];
  let week = [];

  const daysInPreviousMonth = getFirstDayIndexInMonth(
    currentYear,
    currentMonth
  );
  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
  const daysLeftForNextMonth =
    (daysInPreviousMonth + daysInCurrentMonth) % DAYS_IN_ONE_WEEK;
  const daysInNextMonth =
    daysLeftForNextMonth === 0 ? 0 : DAYS_IN_ONE_WEEK - daysLeftForNextMonth;
  const totalDays = daysInPreviousMonth + daysInCurrentMonth + daysInNextMonth;

  let { year, month } = getPreviousMonth(currentYear, currentMonth);
  let day = getDaysInMonth(year, month) - daysInPreviousMonth + 1;

  for (let d = 1; d <= totalDays; d += 1, day += 1) {
    if (isPreviousMonth && d > daysInPreviousMonth) {
      year = currentYear;
      month = currentMonth;
      day = FIRST_DAY;
      isPreviousMonth = false;
      isCurrentMonth = true;
    } else if (isCurrentMonth && d > daysInPreviousMonth + daysInCurrentMonth) {
      ({ year, month } = getNextMonth(currentYear, currentMonth));
      day = FIRST_DAY;
      isCurrentMonth = false;
    }
    const dayInfo = getDayInfo(
      year,
      month,
      day,
      selectedDate,
      disableDates,
      minMaxDates,
      focusedDate,
      labelsMerged,
      isCurrentMonth
    );
    if (d % DAYS_IN_ONE_WEEK === 0) {
      week.push(dayInfo);
      weeks.push(week);
      week = [];
    } else {
      week.push(dayInfo);
    }
    focusedDateId = dayInfo.isFocused ? dayInfo.id : focusedDateId;
  }

  return { weeks, focusedDateId };
};

const checkFirstAndLastMonth = (
  monthOptions,
  minYear,
  maxYear,
  year,
  month
) => {
  const months = monthOptions.map((m) => m.value);
  const isFirstMonth = months.indexOf(month) === 0;
  const isLastMonth = months.indexOf(month) === months.length - 1;
  const isMinYear = minYear === year;
  const isMaxYear = maxYear === year;

  return {
    isFirstMonth: (isFirstMonth && isMinYear) || false,
    isLastMonth: (isLastMonth && isMaxYear) || false
  };
};

const getMonths = (labels) => {
  const { locale, month } = labels;
  const year = 2021; // Could be any year
  const result = [...Array(MONTHS_IN_ONE_YEAR).keys()].map((m) =>
    createDate(Date.UTC(year, m)).toLocaleString(locale, {
      timeZone: 'UTC',
      month
    })
  );
  return result;
};

const getDaysOfTheWeek = (labels) => {
  const { locale, weekday } = labels;
  const year = 2021; // Could be any year
  const month = 2; // 2 corresponds to Sunday as the first day of the week in UTC
  const result = [...Array(DAYS_IN_ONE_WEEK).keys()].map((day) =>
    createDate(Date.UTC(year, month, day)).toLocaleString(locale, {
      timeZone: 'UTC',
      weekday
    })
  );
  return result;
};

const getYears = (minYear, maxYear, labels, enabledYears) => {
  const { locale, year } = labels;
  const format = new Intl.DateTimeFormat(locale, { year }).format;
  const range = maxYear - minYear + 1;
  const yearArg = 1; // January
  const dayArg = 1;
  return [...Array(range).keys()]
    .map((y) => ({
      text: format(Date.UTC(y + minYear, yearArg, dayArg)),
      value: y + minYear
    }))
    .filter((y) => enabledYears.indexOf(y.value) !== -1);
};

const formatDate = (date, labels) => {
  const { locale, month, year, day, weekday } = labels;
  const options = {
    ...(month && { month }),
    ...(year && { year }),
    ...(day && { day }),
    ...(weekday !== NONE && { weekday })
  };
  return new Intl.DateTimeFormat(locale, options).format(date);
};

const getEnabledDates = (minMaxDates, disableDates) => {
  let { min } = minMaxDates;
  const year = min.getFullYear();
  const month = min.getMonth();
  let day = min.getDate();
  let isDisabled = false;
  let isInRange = false;
  let months = {};
  let years = [];
  let date;
  let currYear;

  do {
    date = createDate(year, month, day);
    isInRange = checkInRange(minMaxDates, date);
    isDisabled = checkDisabled(disableDates, date);
    currYear = date.getFullYear();
    if (!isDisabled) {
      years.push(currYear);
      months[currYear] = months[currYear] || [];
      months[currYear].push(date.getMonth() + 1);
    }
    day += ONE_DAY;
  } while (isInRange);

  const sortFunc = (a, b) => a - b;
  Object.entries(months).forEach(
    ([year]) => (months[year] = [...new Set(months[year])].sort(sortFunc))
  );
  years = [...new Set(years)].sort(sortFunc);
  return { months, years };
};

const getFirstEnabledDay = (
  year,
  month,
  minMaxDates,
  disableDates,
  dayInput
) => {
  const monthIndex = month - 1;
  let day = FIRST_DAY;
  let date = createDate(year, monthIndex, dayInput || FIRST_DAY);
  let isDisabled = checkDisabled(disableDates, date);
  let isInRange = checkInRange(minMaxDates, date);

  let { month: normalizedMonth } = normalizeMonthIndex(
    year,
    date.getMonth() + 1
  );
  if (month === normalizedMonth && !isDisabled && isInRange) {
    // The Date constructor would attempt to contruct a date in future months
    // if dayInput is bigger than days in the current month, so need to check month
    return date.getDate();
  }

  // Assume there must be at least one enabled day in the current month
  for (let i = 0; i < MAX_DAYS_IN_ONE_MONTH; i++) {
    date = createDate(year, monthIndex, day);
    isDisabled = checkDisabled(disableDates, date);
    isInRange = checkInRange(minMaxDates, date);
    if (!isDisabled && isInRange) {
      break;
    }
    day += ONE_DAY;
  }

  return date.getDate();
};

export {
  createDate,
  getDaysInMonth,
  getPreviousMonth,
  getNextMonth,
  moveMonth,
  normalizeMonthIndex,
  moveDate,
  checkValidDate,
  checkValidDates,
  checkSameDates,
  checkToday,
  getFirstDayIndexInMonth,
  checkInRange,
  checkDisabled,
  getValidMonth,
  getDayInfo,
  getWeeks,
  checkFirstAndLastMonth,
  getMonths,
  getDaysOfTheWeek,
  getYears,
  formatDate,
  getEnabledDates,
  getFirstEnabledDay
};
