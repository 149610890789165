<template>
  <MdsDialog
    v-model="showSessionTimeOutModal"
    :title="getTitle"
    action-required
    width="500px"
    data-ee-test="session-timeout-dialog"
  >
    <p
      :key="idx"
      v-for="(msg, idx) in $contentTransposer('sessionTimeOut.warningMsg')"
    >
      {{ msg }}
    </p>
    <template #mds-dialog-actions-right>
      <MdsButtonContainer right-aligned>
        <MdsButton
          @click="endSession()"
          type="button"
          variation="secondary"
          data-ee-test="end-session-btn"
        >
          {{ $contentTransposer('button.endSession') }}
        </MdsButton>
        <MdsButton
          @click="extendSession()"
          type="button"
          variation="primary"
          data-ee-test="extend-session-btn"
        >
          {{ $contentTransposer('button.extendMySession') }}
        </MdsButton>
      </MdsButtonContainer>
    </template>
  </MdsDialog>
</template>
<script>
import MdsDialog from '@mds/dialog';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { SESSION_WARNING_TIMER, SESSION_IDLE_TIMER } from '../../constants';
import store from '@/simpleStore';
import pluralize from 'pluralize';

export default {
  components: {
    MdsDialog,
    MdsButton,
    MdsButtonContainer
  },
  data() {
    return {
      showSessionTimeOutModal: false,
      eventLists: [
        'click',
        'mousemove',
        'mousedown',
        'scroll',
        'keypress',
        'load'
      ],
      expireTimeKey: null,
      displayTimeKey: null,
      modalTimeKey: null,
      modalTime: SESSION_WARNING_TIMER,
      expireTime: SESSION_IDLE_TIMER,
      sessionTimeTicker: ''
    };
  },
  computed: {
    getTitle() {
      return `Your session will timeout in ${
        this.sessionTimeTicker !== '5 minutes' ? this.sessionTimeTicker : ''
      }.`;
    }
  },
  mounted() {
    // Add event listners to re-set the session timer.
    this.eventLists.forEach((eventList) => {
      window.addEventListener(eventList, this.resetSessionExpireTimer);
    });
    this.startExpiringSession();
  },
  destroyed() {
    this.clearTimer();
    this.eventLists.forEach((eventList) => {
      window.removeEventListener(eventList, this.resetSessionExpireTimer);
    });
  },
  methods: {
    formatTimer(millis, shortVersion) {
      const minutes = Math.floor(millis / 60000);
      const seconds = ((millis % 60000) / 1000).toFixed(0);
      if (shortVersion) {
        return `(${minutes}:${seconds < 10 ? '0' : ''}${seconds})`;
      }
      if (minutes < 1) {
        return `${seconds} ${pluralize('second', seconds)}`;
      }
      return `${minutes} ${pluralize('minute', minutes)}`;
    },
    clearTimer() {
      clearTimeout(this.expireTimeKey);
      clearTimeout(this.modalTimeKey);
      clearInterval(this.displayTimeKey);
    },
    resetSessionExpireTimer() {
      if (!this.showSessionTimeOutModal) {
        this.clearTimer();
        this.sessionTimeTicker = '';
        this.startExpiringSession();
        document.title = 'easy-enroll';
      }
    },
    endSession() {
      this.clearTimer();
      this.eventLists.forEach((eventList) => {
        window.removeEventListener(eventList, this.resetSessionExpireTimer);
      });
      store.setSessionExpired(true);
      this.$emit('sessionExpired', true);
      document.title = 'easy-enroll';
      this.showSessionTimeOutModal = false;
      // Redirect
      this.$router.push({
        path: '/',
        query: store.getQueryParams()
      });
    },
    extendSession() {
      this.showSessionTimeOutModal = false;
      this.resetSessionExpireTimer();
    },
    startExpiringSession() {
      clearTimeout(this.expireTimeKey);
      this.expireTimeKey = setTimeout(this.endSession, this.expireTime);
      this.modalTimeKey = setTimeout(() => {
        this.showSessionTimeOutModal = true;
        let displayTimer = this.expireTime - this.modalTime;
        this.displayTimeKey = setInterval(() => {
          displayTimer -= 1000;
          this.sessionTimeTicker = this.formatTimer(displayTimer, false);
          document.title = this.formatTimer(displayTimer, true);
        }, 1000);
      }, this.modalTime);
    }
  }
};
</script>
