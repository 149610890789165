<template>
  <mds-modal
    :visible="showModal"
    @mds-modal-dismissed="closeModal($event)"
    aria-labelledby="user-engagement-modal"
    class="user-engagement-modal"
  >
    <mds-section border-position="bottom" bold>
      <template #mds-section-title>
        <span id="user-engagement-modal-title">{{ title }}</span>
      </template>
      <template #mds-section-actions>
        <MdsButton
          @click="closeModal(false)"
          data-ee-test="close-user-engagement-modal-btn"
          variation="icon-only"
          icon="remove"
          type="button"
        />
      </template>
      <div class="modal-content">
        <h2 class="sub-title">{{ subTitle }}</h2>
        <div class="button-container">
          <mds-button
            @click="handleMeeting"
            class="top-button"
            variation="secondary"
            size="touch"
            data-ee-test="user-engagement-modal-top-btn"
            type="button"
          >
            {{ topButtonText }}
          </mds-button>
          <mds-button
            v-if="contactNumber"
            @click="handlePhoneCall"
            class="talk-now-btn"
            variation="primary"
            size="touch"
            data-ee-test="user-engagement-modal-bottom-btn"
            type="button"
          >
            {{ bottomButtonText }} {{ contactNumber }}
          </mds-button>
        </div>
      </div>
    </mds-section>
  </mds-modal>
</template>
<script>
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import { MdsButton } from '@mds/button';
export default {
  name: 'UserEngagementModal',
  components: {
    MdsModal,
    MdsSection,
    MdsButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    subTitle: {
      type: String,
      required: false,
      default: null
    },
    topButtonText: {
      type: String,
      required: false,
      default: null
    },
    bottomButtonText: {
      type: String,
      required: false,
      default: null
    },
    contactNumber: {
      type: String,
      required: false,
      default: null
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false
    },
    modalName: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    closeModal($event) {
      this.$emit('user-engagement-modal-dismissed', $event);
    },
    handleMeeting() {
      this.$emit('track-button-click', 'Meeting', this.modalName);
      window.open(
        'https://calendly.com/morningstar-retirement-education/consultation',
        '_blank'
      );
    },
    handlePhoneCall() {
      this.$emit('track-button-click', 'Phone Call', this.modalName);
      window.open(`tel:${this.contactNumber}`, '_blank');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/user-engagement-modal.scss';
@import '@/assets/css/client/ingg/user-engagement-modal.scss';
</style>
