import { default as defaultModalContent } from './default';
import INGG from './INGG';
import MILL from './MILL';
import SGST from './SGST';
import TRPX from './TRPX';
import USIG from './USIG';
import CAPTRUST_DMP from './CAPTRUST_DMP';
import RESOURCES_DMP from './RESOURCES_DMP';
import CBIZ_DMP from './CBIZ_DMP';
import MESIROW_DMP from './MESIROW_DMP';
import SAGEVIEW_DMP from './SAGEVIEW_DMP';
import INTELLICENTS_DMP from './INTELLICENTS_DMP';
import PENSIONMARK_DMP from './PENSIONMARK_DMP';
import SOLTIS_DMP from './SOLTIS_DMP';
import BFSG_DMP from './BFSG_DMP';
import FISHER_DMP from './FISHER_DMP';
import HUB_DMP from './HUB_DMP';
import NFP_DMP from './NFP_DMP';
import GRP_DMP from './GRP_DMP';
import LOCKTON_DMP from './LOCKTON_DMP';
import MARINER_DMP from './MARINER_DMP';
import INGG_VRA from './INGG_VRA';
import QPA_DMP from './QPA_DMP';

export default {
  defaultModalContent,
  INGG,
  MILL,
  SGST,
  TRPX,
  USIG,
  CAPTRUST_DMP,
  RESOURCES_DMP,
  CBIZ_DMP,
  MESIROW_DMP,
  SAGEVIEW_DMP,
  INTELLICENTS_DMP,
  PENSIONMARK_DMP,
  SOLTIS_DMP,
  BFSG_DMP,
  FISHER_DMP,
  HUB_DMP,
  NFP_DMP,
  GRP_DMP,
  LOCKTON_DMP,
  MARINER_DMP,
  INGG_VRA,
  QPA_DMP
};
